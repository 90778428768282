exports.components = {
  "component---src-pages-2023-01-direito-civil-secao-1-tsx": () => import("./../../../src/pages/2023-01/direito-civil-secao-1.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-civil-secao-1-tsx" */),
  "component---src-pages-2023-01-direito-civil-secao-2-tsx": () => import("./../../../src/pages/2023-01/direito-civil-secao-2.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-civil-secao-2-tsx" */),
  "component---src-pages-2023-01-direito-civil-secao-3-tsx": () => import("./../../../src/pages/2023-01/direito-civil-secao-3.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-civil-secao-3-tsx" */),
  "component---src-pages-2023-01-direito-civil-secao-4-tsx": () => import("./../../../src/pages/2023-01/direito-civil-secao-4.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-civil-secao-4-tsx" */),
  "component---src-pages-2023-01-direito-civil-secao-5-tsx": () => import("./../../../src/pages/2023-01/direito-civil-secao-5.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-civil-secao-5-tsx" */),
  "component---src-pages-2023-01-direito-civil-secao-6-tsx": () => import("./../../../src/pages/2023-01/direito-civil-secao-6.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-civil-secao-6-tsx" */),
  "component---src-pages-2023-01-direito-civil-tsx": () => import("./../../../src/pages/2023-01/direito-civil.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-civil-tsx" */),
  "component---src-pages-2023-01-direito-constitucional-secao-1-tsx": () => import("./../../../src/pages/2023-01/direito-constitucional-secao-1.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-constitucional-secao-1-tsx" */),
  "component---src-pages-2023-01-direito-constitucional-secao-2-tsx": () => import("./../../../src/pages/2023-01/direito-constitucional-secao-2.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-constitucional-secao-2-tsx" */),
  "component---src-pages-2023-01-direito-constitucional-secao-3-tsx": () => import("./../../../src/pages/2023-01/direito-constitucional-secao-3.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-constitucional-secao-3-tsx" */),
  "component---src-pages-2023-01-direito-constitucional-secao-4-tsx": () => import("./../../../src/pages/2023-01/direito-constitucional-secao-4.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-constitucional-secao-4-tsx" */),
  "component---src-pages-2023-01-direito-constitucional-secao-5-tsx": () => import("./../../../src/pages/2023-01/direito-constitucional-secao-5.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-constitucional-secao-5-tsx" */),
  "component---src-pages-2023-01-direito-constitucional-secao-6-tsx": () => import("./../../../src/pages/2023-01/direito-constitucional-secao-6.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-constitucional-secao-6-tsx" */),
  "component---src-pages-2023-01-direito-constitucional-tsx": () => import("./../../../src/pages/2023-01/direito-constitucional.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-constitucional-tsx" */),
  "component---src-pages-2023-01-direito-penal-secao-1-tsx": () => import("./../../../src/pages/2023-01/direito-penal-secao-1.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-penal-secao-1-tsx" */),
  "component---src-pages-2023-01-direito-penal-secao-2-tsx": () => import("./../../../src/pages/2023-01/direito-penal-secao-2.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-penal-secao-2-tsx" */),
  "component---src-pages-2023-01-direito-penal-secao-3-tsx": () => import("./../../../src/pages/2023-01/direito-penal-secao-3.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-penal-secao-3-tsx" */),
  "component---src-pages-2023-01-direito-penal-secao-4-tsx": () => import("./../../../src/pages/2023-01/direito-penal-secao-4.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-penal-secao-4-tsx" */),
  "component---src-pages-2023-01-direito-penal-secao-5-tsx": () => import("./../../../src/pages/2023-01/direito-penal-secao-5.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-penal-secao-5-tsx" */),
  "component---src-pages-2023-01-direito-penal-secao-6-tsx": () => import("./../../../src/pages/2023-01/direito-penal-secao-6.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-penal-secao-6-tsx" */),
  "component---src-pages-2023-01-direito-penal-tsx": () => import("./../../../src/pages/2023-01/direito-penal.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-penal-tsx" */),
  "component---src-pages-2023-01-direito-trabalhista-secao-1-tsx": () => import("./../../../src/pages/2023-01/direito-trabalhista-secao-1.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-trabalhista-secao-1-tsx" */),
  "component---src-pages-2023-01-direito-trabalhista-secao-2-tsx": () => import("./../../../src/pages/2023-01/direito-trabalhista-secao-2.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-trabalhista-secao-2-tsx" */),
  "component---src-pages-2023-01-direito-trabalhista-secao-3-tsx": () => import("./../../../src/pages/2023-01/direito-trabalhista-secao-3.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-trabalhista-secao-3-tsx" */),
  "component---src-pages-2023-01-direito-trabalhista-secao-4-tsx": () => import("./../../../src/pages/2023-01/direito-trabalhista-secao-4.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-trabalhista-secao-4-tsx" */),
  "component---src-pages-2023-01-direito-trabalhista-secao-5-tsx": () => import("./../../../src/pages/2023-01/direito-trabalhista-secao-5.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-trabalhista-secao-5-tsx" */),
  "component---src-pages-2023-01-direito-trabalhista-secao-6-tsx": () => import("./../../../src/pages/2023-01/direito-trabalhista-secao-6.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-trabalhista-secao-6-tsx" */),
  "component---src-pages-2023-01-direito-trabalhista-tsx": () => import("./../../../src/pages/2023-01/direito-trabalhista.tsx" /* webpackChunkName: "component---src-pages-2023-01-direito-trabalhista-tsx" */),
  "component---src-pages-2023-01-index-tsx": () => import("./../../../src/pages/2023-01/index.tsx" /* webpackChunkName: "component---src-pages-2023-01-index-tsx" */),
  "component---src-pages-2023-02-direito-civil-secao-1-tsx": () => import("./../../../src/pages/2023-02/direito-civil-secao-1.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-civil-secao-1-tsx" */),
  "component---src-pages-2023-02-direito-civil-secao-2-tsx": () => import("./../../../src/pages/2023-02/direito-civil-secao-2.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-civil-secao-2-tsx" */),
  "component---src-pages-2023-02-direito-civil-secao-3-tsx": () => import("./../../../src/pages/2023-02/direito-civil-secao-3.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-civil-secao-3-tsx" */),
  "component---src-pages-2023-02-direito-civil-secao-4-tsx": () => import("./../../../src/pages/2023-02/direito-civil-secao-4.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-civil-secao-4-tsx" */),
  "component---src-pages-2023-02-direito-civil-secao-5-tsx": () => import("./../../../src/pages/2023-02/direito-civil-secao-5.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-civil-secao-5-tsx" */),
  "component---src-pages-2023-02-direito-civil-secao-6-tsx": () => import("./../../../src/pages/2023-02/direito-civil-secao-6.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-civil-secao-6-tsx" */),
  "component---src-pages-2023-02-direito-civil-tsx": () => import("./../../../src/pages/2023-02/direito-civil.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-civil-tsx" */),
  "component---src-pages-2023-02-direito-constitucional-secao-1-tsx": () => import("./../../../src/pages/2023-02/direito-constitucional-secao-1.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-constitucional-secao-1-tsx" */),
  "component---src-pages-2023-02-direito-constitucional-secao-2-tsx": () => import("./../../../src/pages/2023-02/direito-constitucional-secao-2.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-constitucional-secao-2-tsx" */),
  "component---src-pages-2023-02-direito-constitucional-secao-3-tsx": () => import("./../../../src/pages/2023-02/direito-constitucional-secao-3.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-constitucional-secao-3-tsx" */),
  "component---src-pages-2023-02-direito-constitucional-secao-4-tsx": () => import("./../../../src/pages/2023-02/direito-constitucional-secao-4.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-constitucional-secao-4-tsx" */),
  "component---src-pages-2023-02-direito-constitucional-secao-5-tsx": () => import("./../../../src/pages/2023-02/direito-constitucional-secao-5.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-constitucional-secao-5-tsx" */),
  "component---src-pages-2023-02-direito-constitucional-secao-6-tsx": () => import("./../../../src/pages/2023-02/direito-constitucional-secao-6.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-constitucional-secao-6-tsx" */),
  "component---src-pages-2023-02-direito-constitucional-tsx": () => import("./../../../src/pages/2023-02/direito-constitucional.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-constitucional-tsx" */),
  "component---src-pages-2023-02-direito-penal-secao-1-tsx": () => import("./../../../src/pages/2023-02/direito-penal-secao-1.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-penal-secao-1-tsx" */),
  "component---src-pages-2023-02-direito-penal-secao-2-tsx": () => import("./../../../src/pages/2023-02/direito-penal-secao-2.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-penal-secao-2-tsx" */),
  "component---src-pages-2023-02-direito-penal-secao-3-tsx": () => import("./../../../src/pages/2023-02/direito-penal-secao-3.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-penal-secao-3-tsx" */),
  "component---src-pages-2023-02-direito-penal-secao-4-tsx": () => import("./../../../src/pages/2023-02/direito-penal-secao-4.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-penal-secao-4-tsx" */),
  "component---src-pages-2023-02-direito-penal-secao-5-tsx": () => import("./../../../src/pages/2023-02/direito-penal-secao-5.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-penal-secao-5-tsx" */),
  "component---src-pages-2023-02-direito-penal-secao-6-tsx": () => import("./../../../src/pages/2023-02/direito-penal-secao-6.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-penal-secao-6-tsx" */),
  "component---src-pages-2023-02-direito-penal-tsx": () => import("./../../../src/pages/2023-02/direito-penal.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-penal-tsx" */),
  "component---src-pages-2023-02-direito-trabalhista-secao-1-tsx": () => import("./../../../src/pages/2023-02/direito-trabalhista-secao-1.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-trabalhista-secao-1-tsx" */),
  "component---src-pages-2023-02-direito-trabalhista-secao-2-tsx": () => import("./../../../src/pages/2023-02/direito-trabalhista-secao-2.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-trabalhista-secao-2-tsx" */),
  "component---src-pages-2023-02-direito-trabalhista-secao-3-tsx": () => import("./../../../src/pages/2023-02/direito-trabalhista-secao-3.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-trabalhista-secao-3-tsx" */),
  "component---src-pages-2023-02-direito-trabalhista-secao-4-tsx": () => import("./../../../src/pages/2023-02/direito-trabalhista-secao-4.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-trabalhista-secao-4-tsx" */),
  "component---src-pages-2023-02-direito-trabalhista-secao-5-tsx": () => import("./../../../src/pages/2023-02/direito-trabalhista-secao-5.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-trabalhista-secao-5-tsx" */),
  "component---src-pages-2023-02-direito-trabalhista-secao-6-tsx": () => import("./../../../src/pages/2023-02/direito-trabalhista-secao-6.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-trabalhista-secao-6-tsx" */),
  "component---src-pages-2023-02-direito-trabalhista-tsx": () => import("./../../../src/pages/2023-02/direito-trabalhista.tsx" /* webpackChunkName: "component---src-pages-2023-02-direito-trabalhista-tsx" */),
  "component---src-pages-2023-02-index-tsx": () => import("./../../../src/pages/2023-02/index.tsx" /* webpackChunkName: "component---src-pages-2023-02-index-tsx" */),
  "component---src-pages-2024-01-direito-civil-secao-1-tsx": () => import("./../../../src/pages/2024-01/direito-civil-secao-1.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-civil-secao-1-tsx" */),
  "component---src-pages-2024-01-direito-civil-secao-2-tsx": () => import("./../../../src/pages/2024-01/direito-civil-secao-2.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-civil-secao-2-tsx" */),
  "component---src-pages-2024-01-direito-civil-secao-3-tsx": () => import("./../../../src/pages/2024-01/direito-civil-secao-3.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-civil-secao-3-tsx" */),
  "component---src-pages-2024-01-direito-civil-secao-4-tsx": () => import("./../../../src/pages/2024-01/direito-civil-secao-4.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-civil-secao-4-tsx" */),
  "component---src-pages-2024-01-direito-civil-secao-5-tsx": () => import("./../../../src/pages/2024-01/direito-civil-secao-5.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-civil-secao-5-tsx" */),
  "component---src-pages-2024-01-direito-civil-secao-6-tsx": () => import("./../../../src/pages/2024-01/direito-civil-secao-6.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-civil-secao-6-tsx" */),
  "component---src-pages-2024-01-direito-civil-tsx": () => import("./../../../src/pages/2024-01/direito-civil.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-civil-tsx" */),
  "component---src-pages-2024-01-direito-constitucional-secao-1-tsx": () => import("./../../../src/pages/2024-01/direito-constitucional-secao-1.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-constitucional-secao-1-tsx" */),
  "component---src-pages-2024-01-direito-constitucional-secao-2-tsx": () => import("./../../../src/pages/2024-01/direito-constitucional-secao-2.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-constitucional-secao-2-tsx" */),
  "component---src-pages-2024-01-direito-constitucional-secao-3-tsx": () => import("./../../../src/pages/2024-01/direito-constitucional-secao-3.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-constitucional-secao-3-tsx" */),
  "component---src-pages-2024-01-direito-constitucional-secao-4-tsx": () => import("./../../../src/pages/2024-01/direito-constitucional-secao-4.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-constitucional-secao-4-tsx" */),
  "component---src-pages-2024-01-direito-constitucional-secao-5-tsx": () => import("./../../../src/pages/2024-01/direito-constitucional-secao-5.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-constitucional-secao-5-tsx" */),
  "component---src-pages-2024-01-direito-constitucional-secao-6-tsx": () => import("./../../../src/pages/2024-01/direito-constitucional-secao-6.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-constitucional-secao-6-tsx" */),
  "component---src-pages-2024-01-direito-constitucional-tsx": () => import("./../../../src/pages/2024-01/direito-constitucional.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-constitucional-tsx" */),
  "component---src-pages-2024-01-direito-penal-secao-1-tsx": () => import("./../../../src/pages/2024-01/direito-penal-secao-1.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-penal-secao-1-tsx" */),
  "component---src-pages-2024-01-direito-penal-secao-2-tsx": () => import("./../../../src/pages/2024-01/direito-penal-secao-2.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-penal-secao-2-tsx" */),
  "component---src-pages-2024-01-direito-penal-secao-3-tsx": () => import("./../../../src/pages/2024-01/direito-penal-secao-3.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-penal-secao-3-tsx" */),
  "component---src-pages-2024-01-direito-penal-secao-4-tsx": () => import("./../../../src/pages/2024-01/direito-penal-secao-4.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-penal-secao-4-tsx" */),
  "component---src-pages-2024-01-direito-penal-secao-5-tsx": () => import("./../../../src/pages/2024-01/direito-penal-secao-5.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-penal-secao-5-tsx" */),
  "component---src-pages-2024-01-direito-penal-secao-6-tsx": () => import("./../../../src/pages/2024-01/direito-penal-secao-6.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-penal-secao-6-tsx" */),
  "component---src-pages-2024-01-direito-penal-tsx": () => import("./../../../src/pages/2024-01/direito-penal.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-penal-tsx" */),
  "component---src-pages-2024-01-direito-trabalhista-secao-1-tsx": () => import("./../../../src/pages/2024-01/direito-trabalhista-secao-1.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-trabalhista-secao-1-tsx" */),
  "component---src-pages-2024-01-direito-trabalhista-secao-2-tsx": () => import("./../../../src/pages/2024-01/direito-trabalhista-secao-2.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-trabalhista-secao-2-tsx" */),
  "component---src-pages-2024-01-direito-trabalhista-secao-3-tsx": () => import("./../../../src/pages/2024-01/direito-trabalhista-secao-3.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-trabalhista-secao-3-tsx" */),
  "component---src-pages-2024-01-direito-trabalhista-secao-4-tsx": () => import("./../../../src/pages/2024-01/direito-trabalhista-secao-4.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-trabalhista-secao-4-tsx" */),
  "component---src-pages-2024-01-direito-trabalhista-secao-5-tsx": () => import("./../../../src/pages/2024-01/direito-trabalhista-secao-5.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-trabalhista-secao-5-tsx" */),
  "component---src-pages-2024-01-direito-trabalhista-secao-6-tsx": () => import("./../../../src/pages/2024-01/direito-trabalhista-secao-6.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-trabalhista-secao-6-tsx" */),
  "component---src-pages-2024-01-direito-trabalhista-tsx": () => import("./../../../src/pages/2024-01/direito-trabalhista.tsx" /* webpackChunkName: "component---src-pages-2024-01-direito-trabalhista-tsx" */),
  "component---src-pages-2024-01-index-tsx": () => import("./../../../src/pages/2024-01/index.tsx" /* webpackChunkName: "component---src-pages-2024-01-index-tsx" */),
  "component---src-pages-2024-02-direito-civil-secao-1-tsx": () => import("./../../../src/pages/2024-02/direito-civil-secao-1.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-civil-secao-1-tsx" */),
  "component---src-pages-2024-02-direito-civil-secao-2-tsx": () => import("./../../../src/pages/2024-02/direito-civil-secao-2.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-civil-secao-2-tsx" */),
  "component---src-pages-2024-02-direito-civil-secao-3-tsx": () => import("./../../../src/pages/2024-02/direito-civil-secao-3.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-civil-secao-3-tsx" */),
  "component---src-pages-2024-02-direito-civil-secao-4-tsx": () => import("./../../../src/pages/2024-02/direito-civil-secao-4.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-civil-secao-4-tsx" */),
  "component---src-pages-2024-02-direito-civil-secao-5-tsx": () => import("./../../../src/pages/2024-02/direito-civil-secao-5.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-civil-secao-5-tsx" */),
  "component---src-pages-2024-02-direito-civil-secao-6-tsx": () => import("./../../../src/pages/2024-02/direito-civil-secao-6.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-civil-secao-6-tsx" */),
  "component---src-pages-2024-02-direito-civil-tsx": () => import("./../../../src/pages/2024-02/direito-civil.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-civil-tsx" */),
  "component---src-pages-2024-02-direito-constitucional-secao-1-tsx": () => import("./../../../src/pages/2024-02/direito-constitucional-secao-1.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-constitucional-secao-1-tsx" */),
  "component---src-pages-2024-02-direito-constitucional-secao-2-tsx": () => import("./../../../src/pages/2024-02/direito-constitucional-secao-2.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-constitucional-secao-2-tsx" */),
  "component---src-pages-2024-02-direito-constitucional-secao-3-tsx": () => import("./../../../src/pages/2024-02/direito-constitucional-secao-3.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-constitucional-secao-3-tsx" */),
  "component---src-pages-2024-02-direito-constitucional-secao-4-tsx": () => import("./../../../src/pages/2024-02/direito-constitucional-secao-4.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-constitucional-secao-4-tsx" */),
  "component---src-pages-2024-02-direito-constitucional-secao-5-tsx": () => import("./../../../src/pages/2024-02/direito-constitucional-secao-5.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-constitucional-secao-5-tsx" */),
  "component---src-pages-2024-02-direito-constitucional-secao-6-tsx": () => import("./../../../src/pages/2024-02/direito-constitucional-secao-6.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-constitucional-secao-6-tsx" */),
  "component---src-pages-2024-02-direito-constitucional-tsx": () => import("./../../../src/pages/2024-02/direito-constitucional.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-constitucional-tsx" */),
  "component---src-pages-2024-02-direito-penal-secao-1-tsx": () => import("./../../../src/pages/2024-02/direito-penal-secao-1.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-penal-secao-1-tsx" */),
  "component---src-pages-2024-02-direito-penal-secao-2-tsx": () => import("./../../../src/pages/2024-02/direito-penal-secao-2.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-penal-secao-2-tsx" */),
  "component---src-pages-2024-02-direito-penal-secao-3-tsx": () => import("./../../../src/pages/2024-02/direito-penal-secao-3.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-penal-secao-3-tsx" */),
  "component---src-pages-2024-02-direito-penal-secao-4-tsx": () => import("./../../../src/pages/2024-02/direito-penal-secao-4.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-penal-secao-4-tsx" */),
  "component---src-pages-2024-02-direito-penal-secao-5-tsx": () => import("./../../../src/pages/2024-02/direito-penal-secao-5.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-penal-secao-5-tsx" */),
  "component---src-pages-2024-02-direito-penal-secao-6-tsx": () => import("./../../../src/pages/2024-02/direito-penal-secao-6.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-penal-secao-6-tsx" */),
  "component---src-pages-2024-02-direito-penal-tsx": () => import("./../../../src/pages/2024-02/direito-penal.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-penal-tsx" */),
  "component---src-pages-2024-02-direito-trabalhista-secao-1-tsx": () => import("./../../../src/pages/2024-02/direito-trabalhista-secao-1.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-trabalhista-secao-1-tsx" */),
  "component---src-pages-2024-02-direito-trabalhista-secao-2-tsx": () => import("./../../../src/pages/2024-02/direito-trabalhista-secao-2.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-trabalhista-secao-2-tsx" */),
  "component---src-pages-2024-02-direito-trabalhista-secao-3-tsx": () => import("./../../../src/pages/2024-02/direito-trabalhista-secao-3.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-trabalhista-secao-3-tsx" */),
  "component---src-pages-2024-02-direito-trabalhista-secao-4-tsx": () => import("./../../../src/pages/2024-02/direito-trabalhista-secao-4.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-trabalhista-secao-4-tsx" */),
  "component---src-pages-2024-02-direito-trabalhista-secao-5-tsx": () => import("./../../../src/pages/2024-02/direito-trabalhista-secao-5.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-trabalhista-secao-5-tsx" */),
  "component---src-pages-2024-02-direito-trabalhista-secao-6-tsx": () => import("./../../../src/pages/2024-02/direito-trabalhista-secao-6.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-trabalhista-secao-6-tsx" */),
  "component---src-pages-2024-02-direito-trabalhista-tsx": () => import("./../../../src/pages/2024-02/direito-trabalhista.tsx" /* webpackChunkName: "component---src-pages-2024-02-direito-trabalhista-tsx" */),
  "component---src-pages-2024-02-index-tsx": () => import("./../../../src/pages/2024-02/index.tsx" /* webpackChunkName: "component---src-pages-2024-02-index-tsx" */),
  "component---src-pages-2025-01-direito-civil-secao-1-tsx": () => import("./../../../src/pages/2025-01/direito-civil-secao-1.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-civil-secao-1-tsx" */),
  "component---src-pages-2025-01-direito-civil-secao-2-tsx": () => import("./../../../src/pages/2025-01/direito-civil-secao-2.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-civil-secao-2-tsx" */),
  "component---src-pages-2025-01-direito-civil-secao-3-tsx": () => import("./../../../src/pages/2025-01/direito-civil-secao-3.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-civil-secao-3-tsx" */),
  "component---src-pages-2025-01-direito-civil-secao-4-tsx": () => import("./../../../src/pages/2025-01/direito-civil-secao-4.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-civil-secao-4-tsx" */),
  "component---src-pages-2025-01-direito-civil-secao-5-tsx": () => import("./../../../src/pages/2025-01/direito-civil-secao-5.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-civil-secao-5-tsx" */),
  "component---src-pages-2025-01-direito-civil-secao-6-tsx": () => import("./../../../src/pages/2025-01/direito-civil-secao-6.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-civil-secao-6-tsx" */),
  "component---src-pages-2025-01-direito-civil-tsx": () => import("./../../../src/pages/2025-01/direito-civil.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-civil-tsx" */),
  "component---src-pages-2025-01-direito-constitucional-secao-1-tsx": () => import("./../../../src/pages/2025-01/direito-constitucional-secao-1.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-constitucional-secao-1-tsx" */),
  "component---src-pages-2025-01-direito-constitucional-secao-2-tsx": () => import("./../../../src/pages/2025-01/direito-constitucional-secao-2.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-constitucional-secao-2-tsx" */),
  "component---src-pages-2025-01-direito-constitucional-secao-3-tsx": () => import("./../../../src/pages/2025-01/direito-constitucional-secao-3.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-constitucional-secao-3-tsx" */),
  "component---src-pages-2025-01-direito-constitucional-secao-4-tsx": () => import("./../../../src/pages/2025-01/direito-constitucional-secao-4.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-constitucional-secao-4-tsx" */),
  "component---src-pages-2025-01-direito-constitucional-secao-5-tsx": () => import("./../../../src/pages/2025-01/direito-constitucional-secao-5.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-constitucional-secao-5-tsx" */),
  "component---src-pages-2025-01-direito-constitucional-secao-6-tsx": () => import("./../../../src/pages/2025-01/direito-constitucional-secao-6.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-constitucional-secao-6-tsx" */),
  "component---src-pages-2025-01-direito-constitucional-tsx": () => import("./../../../src/pages/2025-01/direito-constitucional.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-constitucional-tsx" */),
  "component---src-pages-2025-01-direito-penal-secao-1-tsx": () => import("./../../../src/pages/2025-01/direito-penal-secao-1.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-penal-secao-1-tsx" */),
  "component---src-pages-2025-01-direito-penal-secao-2-tsx": () => import("./../../../src/pages/2025-01/direito-penal-secao-2.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-penal-secao-2-tsx" */),
  "component---src-pages-2025-01-direito-penal-secao-3-tsx": () => import("./../../../src/pages/2025-01/direito-penal-secao-3.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-penal-secao-3-tsx" */),
  "component---src-pages-2025-01-direito-penal-secao-4-tsx": () => import("./../../../src/pages/2025-01/direito-penal-secao-4.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-penal-secao-4-tsx" */),
  "component---src-pages-2025-01-direito-penal-secao-5-tsx": () => import("./../../../src/pages/2025-01/direito-penal-secao-5.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-penal-secao-5-tsx" */),
  "component---src-pages-2025-01-direito-penal-secao-6-tsx": () => import("./../../../src/pages/2025-01/direito-penal-secao-6.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-penal-secao-6-tsx" */),
  "component---src-pages-2025-01-direito-penal-tsx": () => import("./../../../src/pages/2025-01/direito-penal.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-penal-tsx" */),
  "component---src-pages-2025-01-direito-trabalhista-secao-1-tsx": () => import("./../../../src/pages/2025-01/direito-trabalhista-secao-1.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-trabalhista-secao-1-tsx" */),
  "component---src-pages-2025-01-direito-trabalhista-secao-2-tsx": () => import("./../../../src/pages/2025-01/direito-trabalhista-secao-2.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-trabalhista-secao-2-tsx" */),
  "component---src-pages-2025-01-direito-trabalhista-secao-3-tsx": () => import("./../../../src/pages/2025-01/direito-trabalhista-secao-3.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-trabalhista-secao-3-tsx" */),
  "component---src-pages-2025-01-direito-trabalhista-secao-4-tsx": () => import("./../../../src/pages/2025-01/direito-trabalhista-secao-4.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-trabalhista-secao-4-tsx" */),
  "component---src-pages-2025-01-direito-trabalhista-secao-5-tsx": () => import("./../../../src/pages/2025-01/direito-trabalhista-secao-5.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-trabalhista-secao-5-tsx" */),
  "component---src-pages-2025-01-direito-trabalhista-secao-6-tsx": () => import("./../../../src/pages/2025-01/direito-trabalhista-secao-6.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-trabalhista-secao-6-tsx" */),
  "component---src-pages-2025-01-direito-trabalhista-tsx": () => import("./../../../src/pages/2025-01/direito-trabalhista.tsx" /* webpackChunkName: "component---src-pages-2025-01-direito-trabalhista-tsx" */),
  "component---src-pages-2025-01-index-tsx": () => import("./../../../src/pages/2025-01/index.tsx" /* webpackChunkName: "component---src-pages-2025-01-index-tsx" */),
  "component---src-pages-2025-02-direito-civil-secao-1-tsx": () => import("./../../../src/pages/2025-02/direito-civil-secao-1.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-civil-secao-1-tsx" */),
  "component---src-pages-2025-02-direito-civil-secao-2-tsx": () => import("./../../../src/pages/2025-02/direito-civil-secao-2.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-civil-secao-2-tsx" */),
  "component---src-pages-2025-02-direito-civil-secao-3-tsx": () => import("./../../../src/pages/2025-02/direito-civil-secao-3.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-civil-secao-3-tsx" */),
  "component---src-pages-2025-02-direito-civil-secao-4-tsx": () => import("./../../../src/pages/2025-02/direito-civil-secao-4.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-civil-secao-4-tsx" */),
  "component---src-pages-2025-02-direito-civil-secao-5-tsx": () => import("./../../../src/pages/2025-02/direito-civil-secao-5.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-civil-secao-5-tsx" */),
  "component---src-pages-2025-02-direito-civil-secao-6-tsx": () => import("./../../../src/pages/2025-02/direito-civil-secao-6.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-civil-secao-6-tsx" */),
  "component---src-pages-2025-02-direito-civil-tsx": () => import("./../../../src/pages/2025-02/direito-civil.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-civil-tsx" */),
  "component---src-pages-2025-02-direito-constitucional-secao-1-tsx": () => import("./../../../src/pages/2025-02/direito-constitucional-secao-1.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-constitucional-secao-1-tsx" */),
  "component---src-pages-2025-02-direito-constitucional-secao-2-tsx": () => import("./../../../src/pages/2025-02/direito-constitucional-secao-2.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-constitucional-secao-2-tsx" */),
  "component---src-pages-2025-02-direito-constitucional-secao-3-tsx": () => import("./../../../src/pages/2025-02/direito-constitucional-secao-3.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-constitucional-secao-3-tsx" */),
  "component---src-pages-2025-02-direito-constitucional-secao-4-tsx": () => import("./../../../src/pages/2025-02/direito-constitucional-secao-4.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-constitucional-secao-4-tsx" */),
  "component---src-pages-2025-02-direito-constitucional-secao-5-tsx": () => import("./../../../src/pages/2025-02/direito-constitucional-secao-5.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-constitucional-secao-5-tsx" */),
  "component---src-pages-2025-02-direito-constitucional-secao-6-tsx": () => import("./../../../src/pages/2025-02/direito-constitucional-secao-6.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-constitucional-secao-6-tsx" */),
  "component---src-pages-2025-02-direito-constitucional-tsx": () => import("./../../../src/pages/2025-02/direito-constitucional.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-constitucional-tsx" */),
  "component---src-pages-2025-02-direito-penal-secao-1-tsx": () => import("./../../../src/pages/2025-02/direito-penal-secao-1.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-penal-secao-1-tsx" */),
  "component---src-pages-2025-02-direito-penal-secao-2-tsx": () => import("./../../../src/pages/2025-02/direito-penal-secao-2.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-penal-secao-2-tsx" */),
  "component---src-pages-2025-02-direito-penal-secao-3-tsx": () => import("./../../../src/pages/2025-02/direito-penal-secao-3.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-penal-secao-3-tsx" */),
  "component---src-pages-2025-02-direito-penal-secao-4-tsx": () => import("./../../../src/pages/2025-02/direito-penal-secao-4.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-penal-secao-4-tsx" */),
  "component---src-pages-2025-02-direito-penal-secao-5-tsx": () => import("./../../../src/pages/2025-02/direito-penal-secao-5.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-penal-secao-5-tsx" */),
  "component---src-pages-2025-02-direito-penal-secao-6-tsx": () => import("./../../../src/pages/2025-02/direito-penal-secao-6.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-penal-secao-6-tsx" */),
  "component---src-pages-2025-02-direito-penal-tsx": () => import("./../../../src/pages/2025-02/direito-penal.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-penal-tsx" */),
  "component---src-pages-2025-02-direito-trabalhista-secao-1-tsx": () => import("./../../../src/pages/2025-02/direito-trabalhista-secao-1.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-trabalhista-secao-1-tsx" */),
  "component---src-pages-2025-02-direito-trabalhista-secao-2-tsx": () => import("./../../../src/pages/2025-02/direito-trabalhista-secao-2.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-trabalhista-secao-2-tsx" */),
  "component---src-pages-2025-02-direito-trabalhista-secao-3-tsx": () => import("./../../../src/pages/2025-02/direito-trabalhista-secao-3.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-trabalhista-secao-3-tsx" */),
  "component---src-pages-2025-02-direito-trabalhista-secao-4-tsx": () => import("./../../../src/pages/2025-02/direito-trabalhista-secao-4.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-trabalhista-secao-4-tsx" */),
  "component---src-pages-2025-02-direito-trabalhista-secao-5-tsx": () => import("./../../../src/pages/2025-02/direito-trabalhista-secao-5.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-trabalhista-secao-5-tsx" */),
  "component---src-pages-2025-02-direito-trabalhista-secao-6-tsx": () => import("./../../../src/pages/2025-02/direito-trabalhista-secao-6.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-trabalhista-secao-6-tsx" */),
  "component---src-pages-2025-02-direito-trabalhista-tsx": () => import("./../../../src/pages/2025-02/direito-trabalhista.tsx" /* webpackChunkName: "component---src-pages-2025-02-direito-trabalhista-tsx" */),
  "component---src-pages-2025-02-index-tsx": () => import("./../../../src/pages/2025-02/index.tsx" /* webpackChunkName: "component---src-pages-2025-02-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-allpages-tsx": () => import("./../../../src/pages/allpages.tsx" /* webpackChunkName: "component---src-pages-allpages-tsx" */),
  "component---src-pages-atividade-discursiva-index-tsx": () => import("./../../../src/pages/atividade-discursiva/index.tsx" /* webpackChunkName: "component---src-pages-atividade-discursiva-index-tsx" */),
  "component---src-pages-direito-civil-empresarial-secao-1-tsx": () => import("./../../../src/pages/direito-civil-empresarial-secao-1.tsx" /* webpackChunkName: "component---src-pages-direito-civil-empresarial-secao-1-tsx" */),
  "component---src-pages-direito-civil-empresarial-secao-2-tsx": () => import("./../../../src/pages/direito-civil-empresarial-secao-2.tsx" /* webpackChunkName: "component---src-pages-direito-civil-empresarial-secao-2-tsx" */),
  "component---src-pages-direito-civil-empresarial-secao-3-tsx": () => import("./../../../src/pages/direito-civil-empresarial-secao-3.tsx" /* webpackChunkName: "component---src-pages-direito-civil-empresarial-secao-3-tsx" */),
  "component---src-pages-direito-civil-empresarial-secao-4-tsx": () => import("./../../../src/pages/direito-civil-empresarial-secao-4.tsx" /* webpackChunkName: "component---src-pages-direito-civil-empresarial-secao-4-tsx" */),
  "component---src-pages-direito-civil-empresarial-secao-5-tsx": () => import("./../../../src/pages/direito-civil-empresarial-secao-5.tsx" /* webpackChunkName: "component---src-pages-direito-civil-empresarial-secao-5-tsx" */),
  "component---src-pages-direito-civil-empresarial-secao-6-tsx": () => import("./../../../src/pages/direito-civil-empresarial-secao-6.tsx" /* webpackChunkName: "component---src-pages-direito-civil-empresarial-secao-6-tsx" */),
  "component---src-pages-direito-civil-empresarial-tsx": () => import("./../../../src/pages/direito-civil-empresarial.tsx" /* webpackChunkName: "component---src-pages-direito-civil-empresarial-tsx" */),
  "component---src-pages-direito-civil-secao-1-tsx": () => import("./../../../src/pages/direito-civil-secao-1.tsx" /* webpackChunkName: "component---src-pages-direito-civil-secao-1-tsx" */),
  "component---src-pages-direito-civil-secao-2-tsx": () => import("./../../../src/pages/direito-civil-secao-2.tsx" /* webpackChunkName: "component---src-pages-direito-civil-secao-2-tsx" */),
  "component---src-pages-direito-civil-secao-3-tsx": () => import("./../../../src/pages/direito-civil-secao-3.tsx" /* webpackChunkName: "component---src-pages-direito-civil-secao-3-tsx" */),
  "component---src-pages-direito-civil-secao-4-tsx": () => import("./../../../src/pages/direito-civil-secao-4.tsx" /* webpackChunkName: "component---src-pages-direito-civil-secao-4-tsx" */),
  "component---src-pages-direito-civil-secao-5-tsx": () => import("./../../../src/pages/direito-civil-secao-5.tsx" /* webpackChunkName: "component---src-pages-direito-civil-secao-5-tsx" */),
  "component---src-pages-direito-civil-secao-6-tsx": () => import("./../../../src/pages/direito-civil-secao-6.tsx" /* webpackChunkName: "component---src-pages-direito-civil-secao-6-tsx" */),
  "component---src-pages-direito-civil-tsx": () => import("./../../../src/pages/direito-civil.tsx" /* webpackChunkName: "component---src-pages-direito-civil-tsx" */),
  "component---src-pages-direito-constitucional-secao-1-tsx": () => import("./../../../src/pages/direito-constitucional-secao-1.tsx" /* webpackChunkName: "component---src-pages-direito-constitucional-secao-1-tsx" */),
  "component---src-pages-direito-constitucional-secao-2-tsx": () => import("./../../../src/pages/direito-constitucional-secao-2.tsx" /* webpackChunkName: "component---src-pages-direito-constitucional-secao-2-tsx" */),
  "component---src-pages-direito-constitucional-secao-3-tsx": () => import("./../../../src/pages/direito-constitucional-secao-3.tsx" /* webpackChunkName: "component---src-pages-direito-constitucional-secao-3-tsx" */),
  "component---src-pages-direito-constitucional-secao-4-tsx": () => import("./../../../src/pages/direito-constitucional-secao-4.tsx" /* webpackChunkName: "component---src-pages-direito-constitucional-secao-4-tsx" */),
  "component---src-pages-direito-constitucional-secao-5-tsx": () => import("./../../../src/pages/direito-constitucional-secao-5.tsx" /* webpackChunkName: "component---src-pages-direito-constitucional-secao-5-tsx" */),
  "component---src-pages-direito-constitucional-secao-6-tsx": () => import("./../../../src/pages/direito-constitucional-secao-6.tsx" /* webpackChunkName: "component---src-pages-direito-constitucional-secao-6-tsx" */),
  "component---src-pages-direito-constitucional-tsx": () => import("./../../../src/pages/direito-constitucional.tsx" /* webpackChunkName: "component---src-pages-direito-constitucional-tsx" */),
  "component---src-pages-direito-penal-secao-1-tsx": () => import("./../../../src/pages/direito-penal-secao-1.tsx" /* webpackChunkName: "component---src-pages-direito-penal-secao-1-tsx" */),
  "component---src-pages-direito-penal-secao-2-tsx": () => import("./../../../src/pages/direito-penal-secao-2.tsx" /* webpackChunkName: "component---src-pages-direito-penal-secao-2-tsx" */),
  "component---src-pages-direito-penal-secao-3-tsx": () => import("./../../../src/pages/direito-penal-secao-3.tsx" /* webpackChunkName: "component---src-pages-direito-penal-secao-3-tsx" */),
  "component---src-pages-direito-penal-secao-4-tsx": () => import("./../../../src/pages/direito-penal-secao-4.tsx" /* webpackChunkName: "component---src-pages-direito-penal-secao-4-tsx" */),
  "component---src-pages-direito-penal-secao-5-tsx": () => import("./../../../src/pages/direito-penal-secao-5.tsx" /* webpackChunkName: "component---src-pages-direito-penal-secao-5-tsx" */),
  "component---src-pages-direito-penal-secao-6-tsx": () => import("./../../../src/pages/direito-penal-secao-6.tsx" /* webpackChunkName: "component---src-pages-direito-penal-secao-6-tsx" */),
  "component---src-pages-direito-penal-tsx": () => import("./../../../src/pages/direito-penal.tsx" /* webpackChunkName: "component---src-pages-direito-penal-tsx" */),
  "component---src-pages-direito-trabalhista-secao-1-tsx": () => import("./../../../src/pages/direito-trabalhista-secao-1.tsx" /* webpackChunkName: "component---src-pages-direito-trabalhista-secao-1-tsx" */),
  "component---src-pages-direito-trabalhista-secao-2-tsx": () => import("./../../../src/pages/direito-trabalhista-secao-2.tsx" /* webpackChunkName: "component---src-pages-direito-trabalhista-secao-2-tsx" */),
  "component---src-pages-direito-trabalhista-secao-3-tsx": () => import("./../../../src/pages/direito-trabalhista-secao-3.tsx" /* webpackChunkName: "component---src-pages-direito-trabalhista-secao-3-tsx" */),
  "component---src-pages-direito-trabalhista-secao-4-tsx": () => import("./../../../src/pages/direito-trabalhista-secao-4.tsx" /* webpackChunkName: "component---src-pages-direito-trabalhista-secao-4-tsx" */),
  "component---src-pages-direito-trabalhista-secao-5-tsx": () => import("./../../../src/pages/direito-trabalhista-secao-5.tsx" /* webpackChunkName: "component---src-pages-direito-trabalhista-secao-5-tsx" */),
  "component---src-pages-direito-trabalhista-secao-6-tsx": () => import("./../../../src/pages/direito-trabalhista-secao-6.tsx" /* webpackChunkName: "component---src-pages-direito-trabalhista-secao-6-tsx" */),
  "component---src-pages-direito-trabalhista-tsx": () => import("./../../../src/pages/direito-trabalhista.tsx" /* webpackChunkName: "component---src-pages-direito-trabalhista-tsx" */),
  "component---src-pages-estagio-supervisionado-index-tsx": () => import("./../../../src/pages/estagio-supervisionado/index.tsx" /* webpackChunkName: "component---src-pages-estagio-supervisionado-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projeto-de-extensao-1-direito-tsx": () => import("./../../../src/pages/projeto-de-extensao/1-direito.tsx" /* webpackChunkName: "component---src-pages-projeto-de-extensao-1-direito-tsx" */),
  "component---src-pages-projeto-de-extensao-2-direito-tsx": () => import("./../../../src/pages/projeto-de-extensao/2-direito.tsx" /* webpackChunkName: "component---src-pages-projeto-de-extensao-2-direito-tsx" */),
  "component---src-pages-projeto-de-extensao-3-direito-tsx": () => import("./../../../src/pages/projeto-de-extensao/3-direito.tsx" /* webpackChunkName: "component---src-pages-projeto-de-extensao-3-direito-tsx" */),
  "component---src-pages-projeto-de-extensao-4-direito-tsx": () => import("./../../../src/pages/projeto-de-extensao/4-direito.tsx" /* webpackChunkName: "component---src-pages-projeto-de-extensao-4-direito-tsx" */),
  "component---src-pages-projeto-de-extensao-administracao-1-programa-de-contexto-a-comunidade-tsx": () => import("./../../../src/pages/projeto-de-extensao/administracao/1-programa-de-contexto-a-comunidade.tsx" /* webpackChunkName: "component---src-pages-projeto-de-extensao-administracao-1-programa-de-contexto-a-comunidade-tsx" */),
  "component---src-pages-projeto-de-extensao-criminologia-1-programa-de-acao-e-difusao-cultural-tsx": () => import("./../../../src/pages/projeto-de-extensao/criminologia/1-programa-de-acao-e-difusao-cultural.tsx" /* webpackChunkName: "component---src-pages-projeto-de-extensao-criminologia-1-programa-de-acao-e-difusao-cultural-tsx" */),
  "component---src-pages-projeto-de-extensao-desenvolvimento-web-1-programa-de-acao-e-difusao-cultural-tsx": () => import("./../../../src/pages/projeto-de-extensao/desenvolvimento-web/1-programa-de-acao-e-difusao-cultural.tsx" /* webpackChunkName: "component---src-pages-projeto-de-extensao-desenvolvimento-web-1-programa-de-acao-e-difusao-cultural-tsx" */),
  "component---src-pages-projeto-de-extensao-direito-1-programa-de-acao-e-difusao-cultural-tsx": () => import("./../../../src/pages/projeto-de-extensao/direito/1-programa-de-acao-e-difusao-cultural.tsx" /* webpackChunkName: "component---src-pages-projeto-de-extensao-direito-1-programa-de-acao-e-difusao-cultural-tsx" */),
  "component---src-pages-projeto-de-extensao-enfermagem-1-programa-de-acao-e-difusao-cultural-tsx": () => import("./../../../src/pages/projeto-de-extensao/enfermagem/1-programa-de-acao-e-difusao-cultural.tsx" /* webpackChunkName: "component---src-pages-projeto-de-extensao-enfermagem-1-programa-de-acao-e-difusao-cultural-tsx" */),
  "component---src-pages-projeto-de-extensao-gestao-publica-1-programa-de-acao-e-difusao-cultural-tsx": () => import("./../../../src/pages/projeto-de-extensao/gestao-publica/1-programa-de-acao-e-difusao-cultural.tsx" /* webpackChunkName: "component---src-pages-projeto-de-extensao-gestao-publica-1-programa-de-acao-e-difusao-cultural-tsx" */),
  "component---src-pages-projeto-de-extensao-index-2-tsx": () => import("./../../../src/pages/projeto-de-extensao/index2.tsx" /* webpackChunkName: "component---src-pages-projeto-de-extensao-index-2-tsx" */),
  "component---src-pages-projeto-de-extensao-index-tsx": () => import("./../../../src/pages/projeto-de-extensao/index.tsx" /* webpackChunkName: "component---src-pages-projeto-de-extensao-index-tsx" */),
  "component---src-pages-projeto-de-extensao-pedagogia-1-programa-de-inovacao-e-empreendedorismo-tsx": () => import("./../../../src/pages/projeto-de-extensao/pedagogia/1-programa-de-inovacao-e-empreendedorismo.tsx" /* webpackChunkName: "component---src-pages-projeto-de-extensao-pedagogia-1-programa-de-inovacao-e-empreendedorismo-tsx" */),
  "component---src-pages-projeto-de-extensao-psicologia-1-programa-de-acao-e-difusao-cultural-tsx": () => import("./../../../src/pages/projeto-de-extensao/psicologia/1-programa-de-acao-e-difusao-cultural.tsx" /* webpackChunkName: "component---src-pages-projeto-de-extensao-psicologia-1-programa-de-acao-e-difusao-cultural-tsx" */),
  "component---src-pages-quem-somos-tsx": () => import("./../../../src/pages/quem-somos.tsx" /* webpackChunkName: "component---src-pages-quem-somos-tsx" */),
  "component---src-pages-templates-teste-tsx": () => import("./../../../src/pages/templates/teste.tsx" /* webpackChunkName: "component---src-pages-templates-teste-tsx" */),
  "component---src-pages-termos-de-uso-tsx": () => import("./../../../src/pages/termos-de-uso.tsx" /* webpackChunkName: "component---src-pages-termos-de-uso-tsx" */),
  "component---src-pages-youtube-tsx": () => import("./../../../src/pages/youtube.tsx" /* webpackChunkName: "component---src-pages-youtube-tsx" */)
}

